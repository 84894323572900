"use client";
import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/navigation";
import { Button } from "~/components/ui/button";
import Link from "next/link";
import { ComponentNotFoundInCMS } from "~/components/cms-not-found";
import { Input } from "~/components/ui/input";
import {
  SearchIcon,
  CloseIcon,
  ChevronUpOutline,
  OutshiftLogoWhite,
} from "~/components/icons";

export const SearchBar = ({ blogCategory }) => {
  const [showInput, setShowInput] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [input, setInput] = useState("");
  const router = useRouter();

  const searchRef = useRef<any | null>(null);

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setShowInput(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleInput = () => setShowInput(!showInput);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      router.push(`/blog/search/${input}`);
      setShowInput(false);
      setInput("");
    }
  };
  if (blogCategory) {
    return (
      <>
        <div className="absolute top-0 z-10 h-[74px] w-full bg-gradient-to-r from-[#9345E1] via-[#05BAD1] to-[#76BA9A]"></div>
        <div className="absolute top-0 z-20 h-[74px] w-full bg-gradient-to-b from-black via-[#000000c6] to-[#0000009f]"></div>
        {showInput ? (
          <div
            className="container sticky top-0  z-50 flex h-[80px] w-full text-white md:h-full md:items-center md:justify-center"
            ref={searchRef}
          >
            <div className="relative flex w-full flex-col rounded-md">
              <div className="z-50 mt-[25px] flex w-full flex-col items-center gap-5 rounded-md border-0 border-white bg-gradient-search-bar px-4 py-2 text-white md:h-1/2 md:border-2 md:py-4">
                <div className="flex w-full items-center justify-between gap-3 md:justify-around">
                  <Button
                    className="hidden hover:bg-opacity-10 md:block"
                    variant={"ghost"}
                    size="icon"
                    asChild
                    aria-label='Search'
                  >
                    <Link href={`/blog/search/${input}`}>
                      <SearchIcon color="white" size={40} />
                    </Link>
                  </Button>
                  <Input
                    placeholder="Search Blog"
                    className="text-white placeholder:text-white"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <Button
                    className=" hidden gap-3 hover:bg-opacity-10 md:flex"
                    variant={"ghost"}
                    size="icon"
                    aria-label='Close'
                    onClick={toggleInput}
                  >
                    <CloseIcon />
                  </Button>
                  <Button
                    variant="secondary"
                    className="ml-3 rounded-[8px] p-2 md:hidden"
                    onClick={toggleInput}
                    aria-label='Search'
                  >
                    {" "}
                    <SearchIcon size={40} />
                  </Button>
                </div>
                <div className="w-full md:hidden">
                  <div className="flex flex-col py-4">
                    {blogCategory
                      .sort((a, b) => a.id - b.id)
                      .map((el, i) => (
                        <div className="flex flex-col items-start" key={i}>
                          {i != 0 && (
                            <div className="ml-[30px] h-[1px] w-[175px] bg-[#ffffff5e] "></div>
                          )}
                          <div className="px-5 lg:px-[50px]">
                            <Button
                              variant="ghost"
                              className="hover:bg-opacity-10"
                              asChild
                              aria-label='link'
                            >
                              <Link
                                href={`/blog/category/${el.attributes.title
                                  .toLowerCase()
                                  .replace(/\s/g, "-")}`}
                                className="uppercase"
                                onClick={() => {
                                  localStorage.setItem("currentPage", "1");
                                }}
                              >
                                {el.attributes.title}
                              </Link>
                            </Button>
                          </div>
                        </div>
                      ))}
                    <div className="mt-6 self-center ">
                      <Button
                        className="flex gap-3 hover:bg-opacity-10"
                        variant={"ghost"}
                        onClick={toggleInput}
                        aria-label='Close'
                      >
                        <div className="rounded-[3px] border border-white p-1">
                          <ChevronUpOutline size={10} />
                        </div>
                        <p className="text-[10px]">CLOSE</p>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container sticky top-0 z-50 flex h-full w-full items-center justify-center">
            <div className={`relative z-50 h-full w-full ${isSticky ? "" : "md:px-6"}`}>
              <div
                className={`min-h-1/2 z-50 flex w-full justify-between ${
                  isSticky
                    ? "rounded-b-[8px] md:border-2 md:border-t-0"
                    : "rounded-[8px] md:border-2 mt-[24px] md:py-4"
                } border-0 border-white bg-gradient-search-bar py-2 text-white  `}
              >
                <div className="hidden md:flex">
                  {isSticky && (
                    <div className="pl-5">
                      <OutshiftLogoWhite />
                    </div>
                  )}
                  {blogCategory
                    .sort((a, b) => a.id - b.id)
                    .map((el, i) => (
                      <div className="flex items-center" key={i}>
                        {i != 0 && (
                          <div className="h-[14px] w-[1px] bg-white"></div>
                        )}
                        <div className="px-1 lg:px-4">
                          <Button
                            variant="ghost"
                            className="hover:bg-opacity-10 hover:text-white"
                            asChild
                            aria-label='link'
                          >
                            <Link
                              href={`/blog/category/${el.attributes.title
                                .toLowerCase()
                                .replace(/\s/g, "-")}`}
                              className="uppercase"
                              onClick={() => {
                                localStorage.setItem("currentPage", "1");
                              }}
                            >
                              <h6 className="text-center text-sm lg:text-base">
                                {el.attributes.title}
                              </h6>
                            </Link>
                          </Button>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="md:hidden">
                  <div className="flex h-full items-center">
                    <img
                      src="/images/graphics/share-link-icon.png"
                      className="ml-5"
                    />
                    <div className="ml-3">
                      <Button
                        variant="ghost"
                        className="hover:bg-opacity-10 hover:text-white"
                        onClick={toggleInput}
                        aria-label='Categories'
                      >
                        <h5 className="uppercase">Categories</h5>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center gap-5 px-5">
                  <h5 className="font-p hidden xl:block">Search Blog</h5>
                  <Button
                    variant="secondary"
                    className="rounded-[8px] p-2"
                    onClick={toggleInput}
                    aria-label='Search'
                  >
                    {" "}
                    <SearchIcon size={40} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <ComponentNotFoundInCMS message="Section could not be loaded" />;
  }
};
