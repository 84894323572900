"use client";
import React, { useState } from "react";
import BlogCard from "~/components/reusables/blog-card";
import { Button } from "~/components/ui/button";

const RecentBlogsComponent = ({ blogs, n = 6 }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="mt-[50px] grid grid-cols-1 gap-5 md:mt-[80px] md:grid-cols-2 md:gap-[38px] xl:grid-cols-3 lg:gap-[70px]">
        {showMore
          ? blogs.slice(0, 4 * n).map((blog, i) => (
              <div key={i} className="relative min-h-[380px] md:min-h-[520px]">
                <BlogCard blog={blog} size="md" />
              </div>
            ))
          : blogs.slice(0, n).map((blog, i) => (
              <div key={i} className="relative min-h-[380px] md:min-h-[520px]">
                <BlogCard blog={blog} size="md" />
              </div>
            ))}
      </div>
      <div className="flex justify-center pt-6 md:pt-[110px]">
        {showMore ? (
          <Button
            variant="outline"
            aria-label='Show less'
            className="border-2 border-black hover:bg-neutral-200"
            onClick={() => setShowMore(!showMore)}
          >
            Show less
          </Button>
        ) : (
          <Button
            variant="outline"
            aria-label='Load more'
            className="border-2 border-black hover:bg-neutral-200"
            onClick={() => setShowMore(!showMore)}
          >
            Load more
          </Button>
        )}
      </div>
    </>
  );
};

export default RecentBlogsComponent;
